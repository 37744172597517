import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getContactTypes(params) {
        const response = await axios.get(API_URL + "/contacttypes", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addContactType(contacttype) {
        contacttype.type = "contacttypes"
        const newJson = dataFormatter.serialize({ stuff: contacttype })
        const response = await axios.post(API_URL + "/contacttypes", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteContactType(contacttypeId) {
        await axios.delete(API_URL + "/contacttypes/" + contacttypeId, { headers: authHeader() });
    },

    async getContactType(contacttypeId) {
        const response = await axios.get(API_URL + "/contacttypes/" + contacttypeId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editContactType(contacttype) {
        contacttype.type = "contacttypes"
        const newJson = dataFormatter.serialize({ stuff: contacttype })
        const response = await axios.patch(API_URL + "/contacttypes/" + contacttype.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




