/* eslint-disable no-unused-vars */
import utilitiesService from '../services/utilities.service';

const initialState = { utilities: null, utility: null };

export const utilities = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getUtilities({ commit }, options) {
            const utilities = await utilitiesService.getUtilities(options);
            commit('getUtilitiesSuccess', utilities);
        },

        async addUtility({ commit }, newutility) {
            await utilitiesService.addUtility(newutility);
        }, 

        async deleteUtility({ commit }, utilityId) {
            await utilitiesService.deleteUtility(utilityId);
        },

        async getUtility({ commit }, utilityId) {
            const utility = await utilitiesService.getUtility(utilityId);
            commit('getUtilitySuccess', utility);
        },

        async editUtility({ commit }, modifiedutility) {
            await utilitiesService.editUtility(modifiedutility); 
        },


    },
    mutations: {
        getUtilitiesSuccess(state, utilities) {
            state.utilities = utilities;
        },
        getUtilitySuccess(state, utility) {
            state.utility = utility;
        },
    },
    getters: {
        getUtilitiesData(state) {
            return state.utilities?.data;
        },
        getUtilitiesMeta(state) {
            return state.utilities?.meta;
        },
        getUtility(state){
            return state.utility;
        }
    }
}

