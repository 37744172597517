import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getIncomingInvoices(params) {
        const response = await axios.get(API_URL + "/incominginvoices", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addIncomingInvoice(incominginvoice) {
        incominginvoice.type = "incominginvoices"
        const newJson = dataFormatter.serialize({ stuff: incominginvoice })
        const response = await axios.post(API_URL + "/incominginvoices", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteIncomingInvoice(incominginvoiceId) {
        await axios.delete(API_URL + "/incominginvoices/" + incominginvoiceId, { headers: authHeader() });
    },

    async getIncomingInvoice(incominginvoiceId) {
        const response = await axios.get(API_URL + "/incominginvoices/" + incominginvoiceId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editIncomingInvoice(incominginvoice) {
        incominginvoice.type = "incominginvoices"
        const newJson = dataFormatter.serialize({ stuff: incominginvoice })
        const response = await axios.patch(API_URL + "/incominginvoices/" + incominginvoice.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




