<template>
  <div id="profile" class="card card-body mt-4">
    <div class="row justify-content-left align-items-center">
      <div class="col-sm-auto col-4">
        <div class="col-sm-auto col-4">
          <material-avatar
            :img="getImage"
            alt="Avatar"
            size="xl"
            shadow="regular"
            circular
          />
        </div>
        <div class="col-sm-auto col-4">
          <material-button v-show="!file" size="sm" type="button">
            <label for="imageInput" class="mb-0 text-white small cursor-pointer">Kép kiválasztása</label>
            <input id="imageInput" type="file" style="display: none;" accept="image/*" @change.prevent="onFileChange">
          </material-button>

          <div v-show="file">
            <material-button class="mx-2" size="sm" type="button" color="danger" @click.prevent="onFileRemove">
              <label class="mb-0 text-white small cursor-pointer"> &#10005; Remove</label>
            </material-button>
            <material-button size="sm" type="button">
              <label for="imageInput" class="mb-0 text-white small cursor-pointer">Change</label>
              <input id="imageInput" type="file" style="display: none;" accept="image/*" @change.prevent="onFileChange">
            </material-button>
          </div>
        </div>
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">{{ user.name }}</h5>
        </div>
      </div>
    </div>
  </div>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Alap Információ</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-12">
          <material-input
            id="Name"
            variant="static"
            v-model:value="user.name"
            label="Név"
            placeholder="Alec"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <material-input
            id="email"
            type="email"
            variant="static"
            label="Email"
            placeholder="example@email.com"
            v-model:value="user.email"
          />
        </div>
        <div class="col-6">
          <material-input
            id="confirmEmail"
            type="email"
            variant="static"
            label="Email konfirmálás"
            placeholder="example@email.com"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 align-self-center">
          <label class="form-label mt-4 ms-0">Nyelv</label>
          <select
            id="choices-language"
            class="form-control"
            name="choices-language"
          >
            <option value="French">Magyar</option>
          </select>
        </div>
        <div class="col-md-6">
          <label class="form-label mt-4 ms-0">Szerepkörök</label>
          <Multiselect
          :value="user_roles"
          :options="async (roles) => {
            await this.$store.dispatch('roles/getRoles');
            roles = this.$store.getters['roles/getRolesData'];
            const arr = await roles.map(arr => ({
              value: arr.id,
              name: arr.name,
            }));

            return arr;
          }"
          mode="tags"
          :close-on-select="false"
          :searchable="true"
          :create-option="true"
          label="name"
          @change="setRoles"
        />
        </div>
      </div>
      <material-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        type="button"
        @click="handleSubmit"
        >Profil frissítés</material-button
      >
    </div>
  </div>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Jelszó módosítása</h5>
    </div>
    <div class="card-body pt-0">
      <div class="mb-3">
        <material-input id="newPassword" type="password" label="Új jelszó" />
      </div>
      <div class="mb-3">
        <material-input
          id="confirmPassword"
          type="password"
          label="Új jelszó konfirmálás"
        />
      </div>
      <h5 class="mt-5">Jelszó követelmény</h5>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">Egy speciális karakter</span>
        </li>
        <li>
          <span class="text-sm">Min. 6 karakter</span>
        </li>
        <li>
          <span class="text-sm">Egy szám (2 javasolt)</span>
        </li>
      </ul>
      <material-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        >Jelszó frissítés</material-button
      >
    </div>
  </div>
  <div id="notifications" class="card mt-4">
    <div class="card-header">
      <h5>Notifications</h5>
      <p class="text-sm">
        Choose how you receive notifications. These notification settings apply
        to the things you’re watching.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <p class="mb-0">Activity</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Email</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Push</p>
              </th>
              <th class="text-center">
                <p class="mb-0">SMS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Mentions</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user mentions you in a comment</span
                  >
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="mentionsEmail"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Mentions Email"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="mentionsPush"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Mentions Push"
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="mentionsSMS"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Mentions SMS"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Comments</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user comments your item.</span
                  >
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="commentsEmail"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Comments Email"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="commentsPush"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Comments Push"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="commentsSMS"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Comments SMS"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Follows</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user follows you.</span
                  >
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="followEmail"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Follow Email"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="followPush"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Follow Push"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="followSMS"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Follow SMS"
                    checked
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <p class="text-sm mb-0">Log in from a new device</p>
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="login Email"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Login Email"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="loginPush"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Login Push"
                    checked
                  />
                </div>
              </td>
              <td>
                <div class="ms-auto">
                  <material-switch
                    id="LoginSMS"
                    class="form-check form-switch mb-0 align-items-center justify-content-center"
                    name="Login SMS"
                    checked
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div id="delete" class="card mt-4">
    <div class="card-header">
      <h5>Fiók törlés</h5>
      <p class="text-sm mb-0">
        A fiók törlésének megerősítésével véglegesen törlöd a felhasználót.
      </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <material-switch id="deleteAccount" name="Delete Account" />
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm"
            >Megerősít</span
          >
          <span class="text-xs d-block">Törölni akarom a felhasználói fiókom.</span>
        </div>
      </div>
      <material-button
        color="secondary"
        variant="outline"
        class="mb-0 ms-auto"
        type="button"
        name="button"
        >Deaktiválás</material-button
      >
      <material-button
        color="danger"
        variant="gradient"
        class="mb-0 ms-2"
        type="button"
        name="button"
        >Fiók törlése</material-button
      >
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import Multiselect from '@vueform/multiselect';
//import ValidationError from "@/components/ValidationError.vue";
import showSwal from "@/mixins/showSwal.js";
import formMixin from "@/mixins/formMixin.js";
import img from "../../../../assets/img/bruce-mars.jpg";
import img1 from "../../../../assets/img/small-logos/logo-slack.svg";
import img2 from "../../../../assets/img/small-logos/logo-spotify.svg";
import img3 from "../../../../assets/img/small-logos/logo-atlassian.svg";
import img4 from "../../../../assets/img/small-logos/logo-asana.svg";
import _ from "lodash";

export default {
  name: "SideNavItem",
  components: {
    MaterialButton,
    MaterialSwitch,
    MaterialAvatar,
    MaterialInput,
    //ValidationError,
    Multiselect
  },
  mixins: [formMixin],
  props: {
    sessions: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      img,
      img1,
      img2,
      img3,
      img4,
      user: {},
      roles: {},
      value: null,
      options: null,
      file: null,
      loading: null,
      initialImageUrl: "",
    };
  },
  computed: {
    getImage() {
      if (!this.user.profile_image || this.loading) return require("@/assets/img/placeholder.jpg")
      else { return this.user.profile_image }
    },
    user_roles: {
        get: function(){
            if(typeof this.user.roles !== 'undefined'){
                const arr = this.user.roles.map(arr => {
                  return arr.id
                });
                return arr;
            } else {
                return null;
            }
        },
    },
  },
  async mounted() {
    this.loading = true
    try {
      await this.$store.dispatch("roles/getRoles");
      await this.$store.dispatch("profile/getProfile");
      this.user = _.omit(this.$store.getters['profile/getUserProfile'], 'links');
      this.roles = this.$store.getters['roles/getRolesData'];
      this.loading = false
      this.initialImageUrl = this.getImage;
    } catch (error) {
      try {
        this.$store.dispatch('auth/logout');
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        return this.$router.push({ name: "Login" });
      }
    }
    this.loading = false

    if (document.getElementById("choices-gender")) {
      var gender = document.getElementById("choices-gender");
      new Choices(gender);
    }

    if (document.getElementById("choices-language")) {
      var language = document.getElementById("choices-language");
      new Choices(language);
    }

    if (document.getElementById("choices-skills")) {
      var skills = document.getElementById("choices-skills");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
      });
    }
  },
  methods: {
    onFileChange(event) {
      this.file = event.target.files[0];
      this.user.profile_image = URL.createObjectURL(this.file);
    },
    onFileRemove() {
      this.file = null
      this.user.profile_image = this.initialImageUrl;
    },
    setRoles(newValue) {
      if (newValue.length !== 0){
        this.user.roles = this.roles.filter(item => {
          return newValue.includes(item.id);
        });
      }
    },
    async handleSubmit() {
      this.resetApiValidation();
      try {
        if (this.file !== null) {
          await this.$store.dispatch("profile/uploadPic", this.file);
          this.user.profile_image = this.$store.getters['profile/getUserProfileImage']
          this.file = null
        }
        await this.$store.dispatch('profile/editProfile', this.user);
        this.user = _.omit(this.$store.getters['profile/getUserProfile'], 'links');
        showSwal.methods.showSwal({
          type: "success",
          message: "Profile updated successfully!",
          width: 500
        });
      } catch (error) {
        this.setApiValidation(error.response.data.errors);
        showSwal.methods.showSwal({
          type: "error",
          message: "Oops, something went wrong!",
          width: 500
        });
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
