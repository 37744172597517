import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getStypes(params) {
        const response = await axios.get(API_URL + "/submetertypes", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addStype(stype) {
        stype.type = "stypes"
        const newJson = dataFormatter.serialize({ stuff: stype })
        const response = await axios.post(API_URL + "/submetertypes", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteStype(stypeId) {
        await axios.delete(API_URL + "/submetertypes/" + stypeId, { headers: authHeader() });
    },

    async getStype(stypeId) {
        const response = await axios.get(API_URL + "/submetertypes/" + stypeId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editStype(stype) {
        stype.type = "stypes"
        const newJson = dataFormatter.serialize({ stuff: stype })
        const response = await axios.patch(API_URL + "/submetertypes/" + stype.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




