/* eslint-disable no-unused-vars */
import countriesService from '../services/countries.service';

const initialState = { countries: null };

export const countries = {
    namespaced: true,
    state: initialState,
    actions: {
        async getCountries({ commit }, options) {
            const countries = await countriesService.getCountries(options);
            commit('getCountriesSuccess', countries);
        },
    },
    mutations: {
        getCountriesSuccess(state, countries) {
            state.countries = countries;
        },
    },
    getters: {
        getCountriesData(state) {
            return state.countries?.data;
        },
    }
}

