/* eslint-disable no-unused-vars */
import handlingsService from '../services/handlings.service';

const initialState = { handlings: null, handling: null };

export const handlings = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getHandlings({ commit }, options) {
            const handlings = await handlingsService.getHandlings(options);
            commit('getHandlingsSuccess', handlings);
        },

        async addHandling({ commit }, newhandling) {
            await handlingsService.addHandling(newhandling);
        }, 

        async deleteHandling({ commit }, handlingId) {
            await handlingsService.deleteHandling(handlingId);
        },

        async getHandling({ commit }, handlingId) {
            const handling = await handlingsService.getHandling(handlingId);
            commit('getHandlingSuccess', handling);
        },

        async editHandling({ commit }, modifiedhandling) {
            await handlingsService.editHandling(modifiedhandling); 
        },


    },
    mutations: {
        getHandlingsSuccess(state, handlings) {
            state.handlings = handlings;
        },
        getHandlingSuccess(state, handling) {
            state.handling = handling;
        },
    },
    getters: {
        getHandlingsData(state) {
            return state.handlings?.data;
        },
        getHandlingsMeta(state) {
            return state.handlings?.meta;
        },
        getHandling(state){
            return state.handling;
        }
    }
}

