/* eslint-disable no-unused-vars */
import calculationService from '../services/calculation.service';

const initialState = { calculation: null };

export const calculation = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getCalculation({ commit }, options) {
            const calculation = await calculationService.getCalculation(options);
            commit('getCalculationSuccess', calculation);
        },
    },
    mutations: {
        getCalculationSuccess(state, calculation) {
            state.calculation = calculation;
        }
    },
    getters: {
        getCalculationData(state) {
            return state.calculation?.data;
        }
    }
}

