/* eslint-disable no-unused-vars */
import priceindexesService from '../services/priceindexes.service';

const initialState = { priceindexes: null, priceindex: null };

export const priceindexes = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getPriceindexes({ commit }, options) {
            const priceindexes = await priceindexesService.getPriceindexes(options);
            commit('getPriceindexesSuccess', priceindexes);
        },

        async addPriceindex({ commit }, newpriceindex) {
            await priceindexesService.addPriceindex(newpriceindex);
        }, 

        async deletePriceindex({ commit }, priceindexId) {
            await priceindexesService.deletePriceindex(priceindexId);
        },

        async getPriceindex({ commit }, priceindexId) {
            const priceindex = await priceindexesService.getPriceindex(priceindexId);
            commit('getPriceindexSuccess', priceindex);
        },

        async editPriceindex({ commit }, modifiedpriceindex) {
            await priceindexesService.editPriceindex(modifiedpriceindex); 
        },


    },
    mutations: {
        getPriceindexesSuccess(state, priceindexes) {
            state.priceindexes = priceindexes;
        },
        getPriceindexSuccess(state, priceindex) {
            state.priceindex = priceindex;
        },
    },
    getters: {
        getPriceindexesData(state) {
            return state.priceindexes?.data;
        },
        getPriceindexesMeta(state) {
            return state.priceindexes?.meta;
        },
        getPriceindex(state){
            return state.priceindex;
        }
    }
}

