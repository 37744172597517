/* eslint-disable no-unused-vars */
import leasedurationsleaseduration from '../services/leasedurations.service';

const initialState = { leasedurations: null, leaseduration: null };

export const leasedurations = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getLeasedurations({ commit }, options) {
            const leasedurations = await leasedurationsleaseduration.getLeasedurations(options);
            commit('getLeasedurationsSuccess', leasedurations);
        },

        async addLeaseduration({ commit }, newleaseduration) {
            await leasedurationsleaseduration.addLeaseduration(newleaseduration);
        }, 

        async deleteLeaseduration({ commit }, leasedurationId) {
            await leasedurationsleaseduration.deleteLeaseduration(leasedurationId);
        },

        async getLeaseduration({ commit }, leasedurationId) {
            const leaseduration = await leasedurationsleaseduration.getLeaseduration(leasedurationId);
            commit('getLeasedurationSuccess', leaseduration);
        },

        async editLeaseduration({ commit }, modifiedleaseduration) {
            await leasedurationsleaseduration.editLeaseduration(modifiedleaseduration); 
        },


    },
    mutations: {
        getLeasedurationsSuccess(state, leasedurations) {
            state.leasedurations = leasedurations;
        },
        getLeasedurationSuccess(state, leaseduration) {
            state.leaseduration = leaseduration;
        },
    },
    getters: {
        getLeasedurationsData(state) {
            return state.leasedurations?.data;
        },
        getLeasedurationsMeta(state) {
            return state.leasedurations?.meta;
        },
        getLeaseduration(state){
            return state.leaseduration;
        }
    }
}

