/* eslint-disable no-unused-vars */
import stypesService from '../services/stypes.service';

const initialState = { stypes: null, stype: null };

export const stypes = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getStypes({ commit }, options) {
            const stypes = await stypesService.getStypes(options);
            commit('getStypesSuccess', stypes);
        },

        async addStype({ commit }, newstype) {
            await stypesService.addStype(newstype);
        }, 

        async deleteStype({ commit }, stypeId) {
            await stypesService.deletestype(stypeId);
        },

        async getStype({ commit }, stypeId) {
            const stype = await stypesService.getStype(stypeId);
            commit('getStypeSuccess', stype);
        },

        async editStype({ commit }, modifiedstype) {
            await stypesService.editStype(modifiedstype); 
        },


    },
    mutations: {
        getStypesSuccess(state, stypes) {
            state.stypes = stypes;
        },
        getStypeSuccess(state, stype) {
            state.stype = stype;
        },
    },
    getters: {
        getStypesData(state) {
            return state.stypes?.data;
        },
        getStypesMeta(state) {
            return state.stypes?.meta;
        },
        getStype(state){
            return state.stype;
        }
    }
}

