<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Mérő regisztráció</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Meters List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div> 
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">POD</label>
                                    <select id="choices-realestate" v-model.number="meter.pod" name="choices-realestate" class="form-control" @change="setRealEstate($event.target.value)">
                                        <option value="">Válassz!</option>
                                        <option v-for="utility in UtilitiesList" :key="utility.id" :value="utility.id">{{ utility.pod }}</option>
                                    </select>
                                    <validation-error :errors="apiValidationErrors.pod"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="realestate_name" v-model:value="meter.realestate_name" label="Hely neve"
                                        name="realestate_name" variant="static" disabled></material-input>
                                </div>

                                <div class="col-5">
                                    <material-input id="realestate_address" v-model:value="meter.realestate_address" label="Hely címe"
                                        name="realestate_address" variant="static" disabled></material-input>
                                </div>
                            </div>

                            <br />

                            <div class="row p-4 bg-light">
                                <div class="col-5">
                                    <material-input id="product_number" v-model:value="meter.product_number" label="Gyáriszám"
                                        name="product_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Mérési egység</label>
                                    <select id="choices-munits" v-model="meter.measurement_units_id" name="choices-munits" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="unit in getMeasurementUnitsList" :key="unit.id" :value="unit.id">{{ unit.name }}</option>
                                    </select>
                                </div> 

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Beüzemelés időpontja</label>
                                    <VueDatePicker id="selected-date" v-model="currentDate" :teleport="true" :format-locale="hu" :locale="hu" :format="format" :enable-time-picker="false" select-text="Választ" cancel-text="Bezár"/>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Pozíció</label>
                                    <select id="choices-position" v-model="meter.position" name="choices-position" class="form-control">
                                        <option value="">Válassz!</option>
                                        <option v-for="area in AreaList" :key="area.id" :value="area.id">{{ area.area_nr }}</option>
                                    </select>
                                </div>
                                
                                <div class="col-5">
                                    <label>Helységek hozzáadása</label>
                                    <MultiSelect v-model:selectValue="meter.area_ids" :options="areas" select-all="true" select-all-label="Mindet kijelöl" cleaner="false" @show="() => setChoice()" />
                                    <validation-error :errors="apiValidationErrors.area_ids"></validation-error>
                                </div>
                            </div>

                            <br />
                            
                            <CButton id="showButton" color="dark" variant="ghost" @click="visible = !visible">Almérő regisztráció</CButton>
                            <CCollapse :visible="visible">
                                <CCard class="mt-3">
                                <CCardBody>
                                    <div class="row border border-secondary rounded p-2">
                                            <div v-for="(data, index) in ownersdata" :key="index">
                                                <div class="row p-4 bg-secondary">
                                                    <div class="col-3">
                                                        <material-input :id="`product_number${index + 1}`" label="Gyári szám"
                                                            :name="`product_number${index + 1}`" variant="static" @input="updateSubmeter(index, 'product_number', $event.target.value)"></material-input>
                                                        <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                                                    </div>

                                                    <div class="col-3">
                                                        <label class="form-label mb-0 ms-0">Mérési egység</label>
                                                        <select :id="`choices-sub-munits${index + 1}`"  @change="updateSubmeter(index, 'measurement_units_id', $event.target.value)" :name="`choices-sub-munits${index + 1}`" class="form-control">
                                                            <option value="">Válassz!</option>
                                                            <option v-for="unit in getMeasurementUnitsList" :key="unit.id" :value="unit.id">{{ unit.name }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-3">
                                                        <label class="form-label mb-0 ms-0">Típus</label>
                                                        <select :id="`choices-sub-types${index + 1}`" @change="updateSubmeter(index, 'submeter_types_id', $event.target.value)" name="choices-sub-types" class="form-control">
                                                            <option value="">Válassz!</option>
                                                            <option v-for="type in getSubmeterTypesList" :key="type.id" :value="type.id">{{ type.name }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-3 d-table">
                                                        <span aria-hidden="true" class="text-xs font-weight-bold d-table-cell align-middle">
                                                            <i v-show="index === 0" class="fas fa-plus-circle" @click="addMore(index)"> Helység Hozzáadása</i>
                                                            <i v-show="index != 0 && ownersdata.length === (index + 1)" class="fas fa-minus-circle" @click="remove(index)"> Remove</i>
                                                        </span>
                                                    </div>

                                                    <div class="col-3">
                                                        <label class="form-label mb-0 ms-0">Beüzemelés időpontja</label>
                                                        <VueDatePicker id="selected-sub-date" name="selected-sub-date" v-model="currentSubDate" @date-update = "(date) => updateSubmeter(index, 'commissioning_date', date)" :teleport="true" :format-locale="hu" :locale="hu" :format="format" :enable-time-picker="false" select-text="Választ" cancel-text="Bezár"/>
                                                    </div>

                                                    <div class="col-3">
                                                        <label class="form-label mb-0 ms-0">Pozíció</label>
                                                        <select :id="`choices-sub-position${index + 1}`" @change="updateSubmeter(index, 'position', $event.target.value)" name="choices-sub-position" class="form-control">
                                                            <option value="">Válassz!</option>
                                                            <option v-for="area in AreaList" :key="area.id" :value="area.id">{{ area.area_nr }}</option>
                                                        </select>
                                                    </div>
                                                    
                                                    <div class="col-3">
                                                        <label>Helységek hozzáadása</label>
                                                        <MultiSelect  @change="(value) => updateSubmeter(index, 'areas', value)" :options="areas" select-all="true" select-all-label="Mindet kijelöl" cleaner="false" @show="() => setChoice()" />
                                                        <validation-error :errors="apiValidationErrors.area_ids"></validation-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CCardBody>
                                </CCard>
                            </CCollapse>

                            <div class="row mt-2 mb-5 d-flex align-items-center">
                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import { ref } from 'vue';
import { CButton, CCollapse, CCard, CCardBody } from '@coreui/vue-pro';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import MultiSelect from "@/components/MultiSelect.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import { hu } from 'date-fns/locale';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: 'AddMeter',
    components: {
        MaterialButton,
        MaterialInput,
        VueDatePicker,
        MultiSelect,
        ValidationError,
        CButton,
        CCollapse,
        CCard,
        CCardBody
    },
    mixins: [formMixin],
    setup() {
        const currentDate = ref(new Date());
        const currentSubDate = ref(new Date());
        const areas = ref([]);
        const visible = ref(false);
        const format = (date) => {
            const day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${year}-${month}-${day}`;
        };
        return {
            color: '#33e8f5',
            currentDate,
            currentSubDate,
            hu,
            format,
            areas,
            visible,
        }
    },
    data() {
        return {
            meter: {},
            submeter: [],
            date: null,
            ownersdata: [
                {
                    ownerName: "",
                    ownership: "",
                },
            ],
        }
    },
    computed: {
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        getMeasurementUnitsList() {
            return this.$store.getters["munits/getMunitsData"];
        },
        UtilitiesList() {
            return this.$store.getters["unetworks/getUnetworksData"];
        },
        AreaList() {
            return this.$store.getters["areas/getAreasData"];
        },
        getutilityList() {
            return this.$store.getters["utilities/getUtilitiesData"];
        },
        getSubmeterTypesList() {
            return this.$store.getters["stypes/getStypesData"];
        },
        watch: {
            AreaList: function () {
                this.setChoice();
            },
        },
        ownerName(index) {
            if (typeof(index) != 'undefined') {
                return this.apiValidationErrors['owner' + (index + 1)];
            }
            return null;
        },
    },
    async mounted() {
        await this.$store.dispatch("realestates/getRealEstates");
        await this.$store.dispatch("unetworks/getUnetworks");
        await this.$store.dispatch("areas/getAreas");
        await this.$store.dispatch("munits/getMunits");
        await this.$store.dispatch("utilities/getUtilities");
        await this.$store.dispatch("stypes/getStypes");

        if (document.getElementById("choices-realestate")) {
            const realestate = document.getElementById("choices-realestate");
            this.realestate = new Choices(realestate);
            const selectedUtility = this.UtilitiesList.filter(value => {
                return value.id === this.realestate.getValue(true)
            });
            this.currentRealEstate = this.getRealEstateList.filter(value => {
                return value.id == selectedUtility[0]?.realestate_id
            });
            await this.$store.dispatch("areas/getAreas", {
                filter: {
                    ...(this.realestate.getValue(true) ? { realestate_id: this.realestate.getValue(true) } : {}),
                },
            })
            document.getElementById('realestate_name').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.name;
            document.getElementById('realestate_address').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.zip + ' ' + this.currentRealEstate[0]?.city + ' ' +  this.currentRealEstate[0]?.address;
        }

        
        if (document.getElementById("choices-munits")) {
            const munits = document.getElementById("choices-munits");
            new Choices(munits);
        }
        

        if (document.getElementById("choices-position")) {
            const position = document.getElementById("choices-position");
            new Choices(position);
        }

        this.ownersdata.forEach((value, index) => {
            if (document.getElementById("choices-sub-munits" + (index + 1))) {
                const munits = document.getElementById("choices-sub-munits" + (index + 1));
                new Choices(munits);
            }

            if (document.getElementById("choices-sub-types" + (index + 1))) {
                const stypes = document.getElementById("choices-sub-types" + (index + 1));
                new Choices(stypes);
            }

            if (document.getElementById("choices-sub-position"+ (index + 1))) {
                const position = document.getElementById("choices-sub-position"+ (index + 1));
                new Choices(position);
            }
        });
    },
    
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                this.meter.currentDate = this.currentDate;
                this.meter.submeter = this.submeter;
                await this.$store.dispatch("meters/addMeter", this.meter);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Főmérő sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "Meters List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        async setRealEstate() {
            const selectedUtility = this.UtilitiesList.filter(value => {
                return value.id === this.realestate.getValue(true)
            });
            this.currentRealEstate = this.getRealEstateList.filter(value => {
                return value.id === selectedUtility[0].realestate_id
            });
            document.getElementById('realestate_name').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.name;
            document.getElementById('realestate_address').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.zip + ' ' + this.currentRealEstate[0]?.city + ' ' +  this.currentRealEstate[0]?.address;
        },
        setChoice() {
            this.areas = this.AreaList.map((item) => ({
                value: item.id, 
                label: item.area_nr,
            }));
        },
        async addMore() {
            this.ownersdata.push({
                ownerName: "",
                ownership: "",
            });
            const arrayLength = this.ownersdata.length;
            await this.$nextTick(function () {
                if (document.getElementById("choices-sub-munits" + arrayLength)) {
                    const munits = document.getElementById("choices-sub-munits" + arrayLength);
                    new Choices(munits);
                }

                if (document.getElementById("choices-sub-types" + arrayLength)) {
                    const stypes = document.getElementById("choices-sub-types" + arrayLength);
                    new Choices(stypes);
                }

                if (document.getElementById("choices-sub-position"+ arrayLength)) {
                    const position = document.getElementById("choices-sub-position"+ arrayLength);
                    new Choices(position);
                }
            });
        },
        remove(index) {
            this.ownersdata.splice(index, 1);
        },
        updateOwner: function(value, index) {
            this.owner['owner' + (index + 1)] = value;
            this.$emit('input', value);
        },
        updateOwnership: function(value, index) {
            this.owner['ownership' + (index + 1)] = value;
            this.$emit('input', value);
        },
        updateSubmeter: function(index, type, value) {
            if (this.submeter[index] === undefined){
                this.submeter[index] = {};
            }
            if (type == 'commissioning_date') {
                this.submeter[index][type] = this.format(value);
            } else {
                this.submeter[index][type] = value;
            }
        }
    }
}
</script>