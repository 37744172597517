/* eslint-disable no-unused-vars */
import functionsService from '../services/sfunctions.service';

const initialState = { sfunctions: null, sfunction: null };

export const sfunctions = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getFunctions({ commit }, options) {
            const sfunctions = await functionsService.getFunctions(options);
            commit('getFunctionsSuccess', sfunctions);
        },

        async addFunction({ commit }, newfunction) {
            await functionsService.addFunction(newfunction);
        }, 

        async deleteFunction({ commit }, functionId) {
            await functionsService.deleteFunction(functionId);
        },

        async getFunction({ commit }, functionId) {
            const sfunction = await functionsService.getFunction(functionId);
            commit('getFunctionSuccess', sfunction);
        },

        async editFunction({ commit }, modifiedfunction) {
            await functionsService.editFunction(modifiedfunction); 
        },


    },
    mutations: {
        getFunctionsSuccess(state, sfunctions) {
            state.sfunctions = sfunctions;
        },
        getFunctionSuccess(state, sfunction) {
            state.sfunction = sfunction;
        },
    },
    getters: {
        getFunctionsData(state) {
            return state.sfunctions?.data;
        },
        getFunctionsMeta(state) {
            return state.sfunctions?.meta;
        },
        getFunction(state){
            return state.sfunction;
        }
    }
}

