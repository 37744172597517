/* eslint-disable no-unused-vars */
import metersService from '../services/meters.service';

const initialState = { meters: null, meter: null };

export const meters = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getMeters({ commit }, options) {
            const meters = await metersService.getMeters(options);
            commit('getMetersSuccess', meters);
        },

        async addMeter({ commit }, newMeter) {
            await metersService.addMeter(newMeter);
        }, 

        async deleteMeter({ commit }, meterId) {
            await metersService.deleteMeter(meterId);
        },

        async getMeter({ commit }, meterId) {
            const meter = await metersService.getMeter(meterId);
            commit('getMeterSuccess', meter);
        },

        async editMeter({ commit }, modifiedMeter) {
            await metersService.editMeter(modifiedMeter); 
        },


    },
    mutations: {
        getMetersSuccess(state, meters) {
            state.meters = meters;
        },
        getMeterSuccess(state, meter) {
            state.meter = meter;
        },
    },
    getters: {
        getMetersData(state) {
            return state.meters?.data;
        },
        getMetersMeta(state) {
            return state.meters?.meta;
        },
        getMeter(state){
            return state.meter;
        }
    }
}

