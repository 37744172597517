/* eslint-disable no-unused-vars */
import unetworksService from '../services/unetworks.service';

const initialState = { unetworks: null, unetwork: null };

export const unetworks = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getUnetworks({ commit }, options) {
            const unetworks = await unetworksService.getUnetworks(options);
            commit('getUnetworksSuccess', unetworks);
        },

        async addUnetwork({ commit }, newunetwork) {
            await unetworksService.addUnetwork(newunetwork);
        }, 

        async deleteUnetwork({ commit }, unetworkId) {
            await unetworksService.deleteUnetwork(unetworkId);
        },

        async getUnetwork({ commit }, unetworkId) {
            const unetwork = await unetworksService.getUnetwork(unetworkId);
            commit('getUnetworkSuccess', unetwork);
        },

        async editUnetwork({ commit }, modifiedunetwork) {
            await unetworksService.editUnetwork(modifiedunetwork); 
        },


    },
    mutations: {
        getUnetworksSuccess(state, unetworks) {
            state.unetworks = unetworks;
        },
        getUnetworkSuccess(state, unetwork) {
            state.unetwork = unetwork;
        },
    },
    getters: {
        getUnetworksData(state) {
            return state.unetworks?.data;
        },
        getUnetworksMeta(state) {
            return state.unetworks?.meta;
        },
        getUnetwork(state){
            return state.unetwork;
        }
    }
}

