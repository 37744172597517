/* eslint-disable no-unused-vars */
import contacttypesService from '../services/contacttypes.service';

const initialState = { contacttypes: null, contacttype: null };

export const contacttypes = {
    namespaced: true,
    state: initialState,
    actions: {
        async getContactTypes({ commit }, options) {
            const contacttypes = await contacttypesService.getContactTypes(options);
            commit('getContactTypesSuccess', contacttypes);
        },

        async addContactType({ commit }, newcontacttype) {
            await contacttypesService.addContactType(newcontacttype);
        },

        async deleteContactType({ commit }, contacttypeId) {
            await contacttypesService.deleteContactType(contacttypeId);
        },

        async getContactType({ commit }, contacttypeId) {
            const contacttype = await contacttypesService.getContactType(contacttypeId);
            commit('getContactTypeSuccess', contacttype);
        },

        async editContactType({ commit }, modifiedcontacttype) {
            await contacttypesService.editContactType(modifiedcontacttype); 
        },


    },
    mutations: {
        getContactTypesSuccess(state, contacttypes) {
            state.contacttypes = contacttypes;
        },
        getContactTypeSuccess(state, contacttype) {
            state.contacttype = contacttype;
        },
    },
    getters: {
        getContactTypesData(state) {
            return state.contacttypes?.data;
        },
        getContactTypesMeta(state) {
            return state.contacttypes?.meta;
        },
        getContactType(state){
            return state.contacttype;
        }
    }
}

