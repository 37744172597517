<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Ingatlan Módosítása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'RealEstates List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div id="wrapper" class="d-flex">
                            <div class="w-25" :style="`
                                        background-image: url(${getImage});
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                        background-position: center;
                                        height: 300px;
                                        
                                        `">
                            </div>
                            <div class="w-75">
                                <div class="row p-3">
                                    <div class="row p-3">
                                        <div class="col-5">
                                            <material-input placeholder="Név" id="name" v-model:value="realestate.name"
                                                name="name" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.name"></validation-error>
                                        </div>

                                        <div class="col-5">
                                            <material-input placeholder="Helyrajzi Szám" id="lot_number" v-model:value="realestate.lot_number"
                                                name="lot_number" variant="static" disabled></material-input>
                                            <validation-error :errors="apiValidationErrors.lot_number"></validation-error>
                                        </div>
                                    </div>

                                    <hr><h2>Cím adatok</h2>

                                    <div class="row p-3">
                                        <div class="col-3">
                                            <select id="choices-countries" placeholder="Ország" v-model="realestate.country_code" name="choices-countries" class="form-control" disabled>
                                                <option value="">Válassz!</option>
                                                <option v-for="country in getcountryList" :key="country.code" :value="country.code">{{ country.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="zip" v-model:value="realestate.zip" placeholder="Irányítószám"
                                                name="zip" variant="static" disabled></material-input>
                                            <validation-error :errors="apiValidationErrors.zip"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="city" v-model:value="realestate.city" placeholder="Város"
                                                name="city" variant="static" disabled></material-input>
                                            <validation-error :errors="apiValidationErrors.city"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="address" v-model:value="realestate.address" placeholder="Cím"
                                                name="address" variant="static" disabled></material-input>
                                            <validation-error :errors="apiValidationErrors.address"></validation-error>
                                        </div>
                                    </div>

                                    <hr><h2>Tulajdonosi adatok</h2>

                                    <div class="row p-3">
                                        <div class="col-3">
                                            <select id="choices-owners" v-model.number="realestate.owner_id" name="choices-owners" class="form-control">
                                                <option value="">Válassz tulajdonost!</option>
                                                <option v-for="owner in getownerList" :key="owner.id" :value="owner.id" v-html="owner.name"></option>
                                            </select>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="ownership" v-model:value="realestate.ownership" placeholder="Tulajdoni hányad"
                                                name="ownership" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.ownership"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="deed_size" v-model:value="realestate.deed_size" placeholder="Tulajdoni lap szerinti alapterület (m2)"
                                            name="deed_size" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.deed_size"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="gross_size" v-model:value="realestate.gross_size" placeholder="Ingatlan bruttó alapterülete (m2)"
                                            name="gross_size" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.gross_size"></validation-error>
                                        </div>
                                    </div>

                                    <hr><h2>Egyéb</h2>

                                    <div class="row p-3">
                                        <div class="col-3">
                                            <material-input id="building_nr" v-model:value="realestate.building_nr" placeholder="Épületek száma (db)"
                                                name="building_nr" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.building_nr"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <select id="choices-sfunctions" v-model.number="realestate.realestate_functions_id" name="choices-sfunctions" class="form-control">
                                                <option value="">Válassz funkciót!</option>
                                                <option v-for="sfunction in getfunctionList" :key="sfunction.id" :value="sfunction.id">{{ sfunction.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-3">
                                            <select id="choices-handlings" v-model.number="realestate.realestate_handlings_id" name="choices-handlings" class="form-control">
                                                <option value="">Válassz kezelési formát!</option>
                                                <option v-for="handling in gethandlingList" :key="handling.id" :value="handling.id">{{ handling.name }}</option>
                                            </select>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="property_manager_name" v-model:value="realestate.property_manager_name" placeholder="Ingatlankezelő neve"
                                                name="property_manager_name" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.property_manager_name"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="property_manager_phone" v-model:value="realestate.property_manager_phone" placeholder="Ingatlankezelő telefonszáma"
                                                name="property_manager_phone" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.property_manager_phone"></validation-error>
                                        </div>

                                        <div class="col-3">
                                            <material-input id="property_manager_email" v-model:value="realestate.property_manager_email" placeholder="Ingatlankezelő e-mail címe"
                                                name="property_manager_email" variant="static"></material-input>
                                            <validation-error :errors="apiValidationErrors.property_manager_email"></validation-error>
                                        </div>
                                    </div>
                                
                            </div>
                            </div>
                        </div>
                        <div class="relative">
                            <div class="w-100">
                                <hr><h2>Helységek hozzáadása</h2>
                            </div>
                        
                            
                            <div class="button_plus" id="showButton" color="dark" variant="ghost" @click="visible = !visible" />
                            <CCollapse :visible="visible" style="position: relative; top: 20px;">
                                <CCard class="mt-3">
                                <CCardBody>
                                    <div class="row border border-secondary rounded p-2">
                                            <div v-for="(data, index) in ownersdata" :key="index">
                                                <div class="row p-4 bg-secondary">
                                                    <div class="row p-2 position-relative">
                                                        <hr><h2>{{ index + 1 }} helység</h2>
                                                    </div>
                                                    <button v-show="index != 0 && ownersdata.length === (index + 1) && data.id == undefined" class="delete-button position-absolute" style="left: 10px" @click.prevent="remove(index)">
                                                        <span>Törlés</span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                        </svg>
                                                    </button>
                                                    <div class="col-4">
                                                        <material-input :id="`building_nr${index + 1}`" v-model:value="data.building_nr"  @input="updateArea(index, 'building_nr', $event.target.value)" placeholder="Épület szám"
                                                            :name="`building_nr${index + 1}`" variant="static"></material-input>
                                                        <validation-error :errors="apiValidationErrors.building_nr"></validation-error>
                                                    </div>

                                                    <div class="col-4">
                                                        <material-input :id="`area_nr${index + 1}`" v-model:value="data.area_nr"  @input="updateArea(index, 'area_nr', $event.target.value)" placeholder="Helyiség száma"
                                                            :name="`area_nr${index + 1}`" variant="static"></material-input>
                                                        <validation-error :errors="apiValidationErrors.area_nr"></validation-error>
                                                    </div>

                                                    <div class="col-4">
                                                        <material-input :id="`lot_number${index + 1}`" v-model:value="data.lot_number"  @input="updateArea(index, 'lot_number', $event.target.value)" placeholder="Helyrajzi szám"
                                                            :name="`lot_number${index + 1}`" variant="static"></material-input>
                                                        <validation-error :errors="apiValidationErrors.lot_number"></validation-error>
                                                    </div>
                                                    
                                                    <div class="col-4">
                                                        <select :id="`choices-area_positions${index + 1}`" @change="updateArea(index, 'area_positions_id', $event.target.value)" :name="`choices-area_positions${index + 1}`" class="form-control">
                                                            <option value="">Válassz elhelyezkedést!</option>
                                                            <option v-for="position in getAreaPositionsList" :key="position.id" :value="position.id">{{ position.name }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-4">
                                                        <select :id="`choices-area_functions${index + 1}`" @change="updateArea(index, 'area_functions_id', $event.target.value)" :name="`choices-area_functions${index + 1}`" class="form-control">
                                                            <option value="">Válassz funkciót!</option>
                                                            <option v-for="afunction in getAreaFunctionsList" :key="afunction.id" :value="afunction.id">{{ afunction.name }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-4">
                                                        <material-input :id="`net_floor_space${index + 1}`" v-model:value="data.net_floor_space"  @input="updateArea(index, 'net_floor_space', $event.target.value)" placeholder="Nettó alapterület"
                                                            :name="`net_floor_space${index + 1}`" variant="static"></material-input>
                                                        <validation-error :errors="apiValidationErrors.net_floor_space"></validation-error>
                                                    </div>

                                                    <div class="col-4">
                                                        <material-input :id="`gross_floor_space${index + 1}`" v-model:value="data.gross_floor_space" @input="updateArea(index, 'gross_floor_space', $event.target.value)" placeholder="Bruttó alapterület"
                                                            :name="`gross_floor_space${index + 1}`" variant="static"></material-input>
                                                        <validation-error :errors="apiValidationErrors.gross_floor_space"></validation-error>
                                                    </div>

                                                    <div class="col-4">
                                                        <material-input :id="`height${index + 1}`" v-model:value="data.height"  @input="updateArea(index, 'height', $event.target.value)" placeholder="Belmagasság"
                                                            :name="`height${index + 1}`" variant="static"></material-input>
                                                        <validation-error :errors="apiValidationErrors.height"></validation-error>
                                                    </div>

                                                    <div class="col-4">
                                                        <select :id="`choices-area_floor_coverings${index + 1}`" @change="updateArea(index, 'area_floor_coverings_id', $event.target.value)" :name="`choices-area_floor_coverings${index + 1}`" class="form-control">
                                                            <option value="">Válassz padlóburkolatot!</option>
                                                            <option v-for="covering in getAreaFloorCoveringsList" :key="covering.id" :value="covering.id">{{ covering.name }}</option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div class="row p-2">
                                                    <div v-show="ownersdata.length === (index + 1)" class="button_plus" id="showButton" color="dark" variant="ghost" @click.prevent="addMore(index)" />
                                                </div>
                                            </div>
                                        </div>
                                    </CCardBody>
                                </CCard>
                            </CCollapse>

                        </div>

                            <div class="row mt-1 mb-5 d-flex align-items-center">
                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleEdit">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import { ref } from 'vue';
import { CButton, CCollapse, CCard, CCardBody } from '@coreui/vue-pro';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import _ from "lodash"

export default {
    name: 'EditRealEstate',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
        CButton,
        CCollapse,
        CCard,
        CCardBody
    },
    mixins: [formMixin],
    setup() {
        const visible = ref(false);
        return {
            visible,
            color: '#33e8f5'
        }
    },
    data() {
        return {
            realestate: {},
            areas: [],
            countries: {},
            owners: {},
            sfunctions: {},
            handlings: {},
            ownersdata: [
                {
                    ownerName: "",
                    ownership: "",
                },
            ],
        }
    },
    computed: {
        getcountryList() {
            return this.$store.getters["countries/getCountriesData"];
        },
        getownerList() {
            return this.$store.getters["owners/getOwnersData"];
        },
        getfunctionList() {
            return this.$store.getters["sfunctions/getFunctionsData"];
        },
        gethandlingList() {
            return this.$store.getters["handlings/getHandlingsData"];
        },
        getAreaPositionsList() {
            return this.$store.getters["positions/getPositionsData"];
        },
        getAreaFunctionsList() {
            return this.$store.getters["afunctions/getFunctionsData"];
        },
        getAreaFloorCoveringsList() {
            return this.$store.getters["coverings/getCoveringsData"];
        },
        AreaList() {
            return this.$store.getters["areas/getAreasData"];
        },
        getImage() {
            if (!this.profile_image) return require("@/assets/img/haz_rajz.jpg");
        else {
            return this.profile_image;
        }
        },
        computedDeedSize: {
            set(val) {
                this.realestate.deed_size = parseFloat(val);
            }
        },
        computedGrossSize: {
            set(val) {
                this.realestate.gross_size = parseFloat(val);
            }
        },
        computedNetFloorSpace: {
            set(val) {
                this.area.net_floor_space = parseFloat(val);
            }
        },
        computedGrossFloorSpace: {
            set(val) {
                this.area.gross_floor_space = parseFloat(val);
            }
        },
        computedHeight: {
            set(val) {
                this.area.height = parseFloat(val);
            }
        },
        computedDataId: {
            get() {
                return this.data.toString()
            },
            set(val) {
                this.data = Number(val)
            }
        },
        ownerName(index) {
            if (typeof(index) != 'undefined') {
                return this.apiValidationErrors['owner' + (index + 1)];
            }
            return null;
        },
    },
    async mounted() {
        await this.$store.dispatch("countries/getCountries");
        await this.$store.dispatch("owners/getOwners");
        await this.$store.dispatch("sfunctions/getFunctions");
        await this.$store.dispatch("handlings/getHandlings");
        await this.$store.dispatch("positions/getPositions");
        await this.$store.dispatch("afunctions/getFunctions");
        await this.$store.dispatch("coverings/getCoverings");
    
        try {
            await this.$store.dispatch("realestates/getRealEstate", this.$route.params.id);
            this.realestate = _.omit(this.$store.getters['realestates/getRealEstate'], 'links');
            this.realestate = this.realestate[0];
            this.realestate.id = this.$route.params.id;
            this.areas = this.realestate.get_areas;
            this.ownersdata = this.realestate.get_areas;
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: `Valami hiba történt!`,
                width: 500
            });
        }
        if (document.getElementById("choices-countries")) {
            var countries = document.getElementById("choices-countries");
            this.countries = new Choices(countries);
            this.countries.setChoiceByValue(this.realestate.country_code);
        }

        if (document.getElementById("choices-owners")) {
            var owners = document.getElementById("choices-owners");
            this.owners = new Choices(owners);
            this.owners.setChoiceByValue(String(this.realestate.owner_id));
        }

        if (document.getElementById("choices-sfunctions")) {
            var sfunctions = document.getElementById("choices-sfunctions");
            this.sfunctions = new Choices(sfunctions);
            this.sfunctions.setChoiceByValue(String(this.realestate.realestate_functions_id));
        }

        if (document.getElementById("choices-handlings")) {
            var handlings = document.getElementById("choices-handlings");
            this.handlings = new Choices(handlings);
            this.handlings.setChoiceByValue(String(this.realestate.realestate_handlings_id));
        }

        this.ownersdata.forEach((value, index) => {
            if (document.getElementById("choices-area_positions" + (index + 1))) {
                const positions = new Choices(document.getElementById("choices-area_positions" + (index + 1)));
                positions.setChoiceByValue(String(value.area_positions_id));
            }

            if (document.getElementById("choices-area_functions" + (index + 1))) {
                const functions = new Choices(document.getElementById("choices-area_functions" + (index + 1)));
                functions.setChoiceByValue(String(value.area_functions_id));
            }

            if (document.getElementById("choices-area_floor_coverings" + (index + 1))) {
                const coverings = new Choices(document.getElementById("choices-area_floor_coverings" + (index + 1)));
                coverings.setChoiceByValue(String(value.area_floor_coverings_id));
            }
        });
    },
    methods: {
        async handleEdit() {
            this.resetApiValidation();
            try {
                this.realestate.deed_size = parseFloat(this.realestate.deed_size);
                this.realestate.gross_size = parseFloat(this.realestate.gross_size);
                this.realestate.areas = this.areas;
                await this.$store.dispatch("realestates/editRealEstate", this.realestate);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Ingatlan sikeresen módosítva!",
                    width: 500
                });
                this.$router.push({ name: "RealEstates List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        async addMore() {
            this.ownersdata.push({
                ownerName: "",
                ownership: "",
            });
            const arrayLength = this.ownersdata.length;
            await this.$nextTick(function () {
                if (document.getElementById("choices-area_positions" + arrayLength)) {
                    const munits = document.getElementById("choices-area_positions" + arrayLength);
                    new Choices(munits);
                }

                if (document.getElementById("choices-area_functions" + arrayLength)) {
                    const stypes = document.getElementById("choices-area_functions" + arrayLength);
                    new Choices(stypes);
                }

                if (document.getElementById("choices-area_floor_coverings"+ arrayLength)) {
                    const position = document.getElementById("choices-area_floor_coverings"+ arrayLength);
                    new Choices(position);
                }
            });
        },
        remove(index) {
            this.ownersdata.splice(index, 1);
        },
        updateOwner: function(value, index) {
            this.owner['owner' + (index + 1)] = value;
            this.$emit('input', value);
        },
        updateOwnership: function(value, index) {
            this.owner['ownership' + (index + 1)] = value;
            this.$emit('input', value);
        },
        updateArea: function(index, type, value) {
            if (this.areas[index] === undefined){
                this.areas[index] = {};
            }
            if (type == 'commissioning_date') {
                this.areas[index][type] = this.format(value);
            } else {
                this.areas[index][type] = value;
            }
        }
    }
}
</script>