/* eslint-disable no-unused-vars */
import realestatesService from '../services/realestates.service';

const initialState = { realestates: null, realestate: null };

export const realestates = {
    namespaced: true,
    state: initialState,
    actions: {
        async getRealEstates({ commit }, options) {
            const realestates = await realestatesService.getRealEstates(options);
            commit('getRealEstatesSuccess', realestates);
        },

        async addRealEstate({ commit }, newRealEstate) {
            await realestatesService.addRealEstate(newRealEstate);
        },

        async deleteRealEstate({ commit }, realestateId) {
            await realestatesService.deleteRealEstate(realestateId);
        },

        async getRealEstate({ commit }, realestateId) {
            const realestate = await realestatesService.getRealEstate(realestateId);
            commit('getRealEstateSuccess', realestate);
        },

        async editRealEstate({ commit }, modifiedRealEstate) {
            await realestatesService.editRealEstate(modifiedRealEstate); 
        },


    },
    mutations: {
        getRealEstatesSuccess(state, realestates) {
            state.realestates = realestates;
        },
        getRealEstateSuccess(state, realestate) {
            state.realestate = realestate;
        },
    },
    getters: {
        getRealEstatesData(state) {
            return state.realestates?.data;
        },
        getRealEstatesMeta(state) {
            return state.realestates?.meta;
        },
        getRealEstate(state){
            return state.realestate;
        }
    }
}

