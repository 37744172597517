<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Helyiség Hozzáadása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Areas List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Ingatlan</label>
                                    <select id="choices-realestate" v-model.number="area.realestate_id" name="choices-realestate" class="form-control"  @change="setPODs($event.target.value)">
                                        <option v-for="realestate in getRealEstateList" :key="realestate.id" :value="realestate.id">{{ realestate.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <material-input id="lot_number" v-model:value="area.lot_number" label="Helyrajzi szám"
                                        name="lot_number" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.lot_number"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="building_nr" v-model:value="area.building_nr" label="Épület szám"
                                        name="building_nr" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.building_nr"></validation-error>
                                </div>
                                
                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Elhelyezkedés</label>
                                    <select id="choices-area_positions" v-model.number="area.area_positions_id" name="choices-area_positions" class="form-control"  @change="setPODs($event.target.value)">
                                        <option v-for="position in getAreaPositionsList" :key="position.id" :value="position.id">{{ position.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <material-input id="area_nr" v-model:value="area.area_nr" label="Helyiség száma"
                                        name="area_nr" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.area_nr"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Helyiség funkció</label>
                                    <select id="choices-area_functions" v-model.number="area.area_functions_id" name="choices-area_functions" class="form-control"  @change="setPODs($event.target.value)">
                                        <option v-for="afunction in getAreaFunctionsList" :key="afunction.id" :value="afunction.id">{{ afunction.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <material-input id="net_floor_space" v-model:value="computedNetFloorSpace" label="Nettó alapterület"
                                        name="net_floor_space" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.net_floor_space"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="gross_floor_space" v-model:value="computedGrossFloorSpace" label="Bruttó alapterület"
                                        name="gross_floor_space" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.gross_floor_space"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="height" v-model.number="computedHeight" label="Belmagasság"
                                        name="height" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.height"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Padlóburkolat</label>
                                    <select id="choices-area_floor_coverings" v-model.number="area.area_floor_coverings_id" name="choices-area_floor_coverings" class="form-control"  @change="setPODs($event.target.value)">
                                        <option v-for="covering in getAreaFloorCoveringsList" :key="covering.id" :value="covering.id">{{ covering.name }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">
                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";

export default {
    name: 'AddArea',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError
    },
    mixins: [formMixin],
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    data() {
        return {
            area: {},
            meters: {},
            subMeters: {},
            metersList: {},
            subMetersList: {},
        }
    },
    computed: {
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        getAreaPositionsList() {
            return this.$store.getters["positions/getPositionsData"];
        },
        getAreaFunctionsList() {
            return this.$store.getters["afunctions/getFunctionsData"];
        },
        getAreaFloorCoveringsList() {
            return this.$store.getters["coverings/getCoveringsData"];
        },
        computedNetFloorSpace: {
            set(val) {
                this.area.net_floor_space = parseFloat(val);
            }
        },
        computedGrossFloorSpace: {
            set(val) {
                this.area.gross_floor_space = parseFloat(val);
            }
        },
        computedHeight: {
            set(val) {
                this.area.height = parseFloat(val);
            }
        },
    },
    async mounted() {
        await this.$store.dispatch("realestates/getRealEstates");
        await this.$store.dispatch("positions/getPositions");
        await this.$store.dispatch("afunctions/getFunctions");
        await this.$store.dispatch("coverings/getCoverings");

        if (document.getElementById("choices-realestate")) {
            var realestate = document.getElementById("choices-realestate");
            this.realestate = new Choices(realestate);
            this.area.realestate_id = parseInt(document.getElementById("choices-realestate").value);
        }

        if (document.getElementById("choices-area_positions")) {
           this.area_positions = new Choices(document.getElementById("choices-area_positions"));
           this.area.area_positions_id = parseInt(document.getElementById("choices-area_positions").value);
        }

        if (document.getElementById("choices-area_functions")) {
           this.area_functions = new Choices(document.getElementById("choices-area_functions"));
           this.area.area_functions_id = parseInt(document.getElementById("choices-area_functions").value);
        }

        if (document.getElementById("choices-area_floor_coverings")) {
           this.area_floor_coverings = new Choices(document.getElementById("choices-area_floor_coverings"));
           this.area.area_floor_coverings_id = parseInt(document.getElementById("choices-area_floor_coverings").value);
        }
    },

    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                //this.tag.color = this.color.hex
                await this.$store.dispatch("areas/addArea", this.area);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Helyiség sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "Areas List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
    }
}
</script>