import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getOwners(params) {
        const response = await axios.get(API_URL + "/owners", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addOwner(owners) {
        owners.type = "owners"
        const newJson = dataFormatter.serialize({ stuff: owners })
        const response = await axios.post(API_URL + "/owners", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteOwner(ownerId) {
        await axios.delete(API_URL + "/owners/" + ownerId, { headers: authHeader() });
    },

    async getOwner(ownerId) {
        const response = await axios.get(API_URL + "/owners/" + ownerId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editOwner(owner) {
        owner.type = "owners"
        const newJson = dataFormatter.serialize({ stuff: owner })
        const response = await axios.patch(API_URL + "/owners/" + owner.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




