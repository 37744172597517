<template>
  <CMultiSelect :id="id" :value="selectValue" allowCreateOptions :options="options" @change="(value) => getSelected(value)" />
</template>

<script>
import { CMultiSelect } from '@coreui/vue-pro';


export default {
  name: "MultiSelect",
  components: {
    CMultiSelect,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    selectValue: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      selectedOption: 0,
      options: [{ 
      }],
      parentValue: {},
    }
  },
  methods: {
    getSelected: function (value) {
      this.$emit('update:selectValue', value);
    }
  },
};
</script>

<style scoped lang="css">
  @import '../../node_modules/@coreui/coreui-pro/dist/css/coreui.min.css';
</style>
