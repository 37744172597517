import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getCoverings(params) {
        const response = await axios.get(API_URL + "/coverings", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addCovering(covering) {
        covering.type = "coverings"
        const newJson = dataFormatter.serialize({ stuff: covering })
        const response = await axios.post(API_URL + "/coverings", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteCovering(coveringId) {
        await axios.delete(API_URL + "/coverings/" + coveringId, { headers: authHeader() });
    },

    async getCovering(coveringId) {
        const response = await axios.get(API_URL + "/coverings/" + coveringId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editCovering(covering) {
        covering.type = "coverings"
        const newJson = dataFormatter.serialize({ stuff: covering })
        const response = await axios.patch(API_URL + "/coverings/" + covering.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




