/* eslint-disable no-unused-vars */
import leasesService from '../services/leases.service';

const initialState = { leases: null, lease: null };

export const leases = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getLeases({ commit }, options) {
            const leases = await leasesService.getLeases(options);
            commit('getLeasesSuccess', leases);
        },

        async addLease({ commit }, newlease) {
            await leasesService.addLease(newlease);
        }, 

        async deleteLease({ commit }, leaseId) {
            await leasesService.deleteLease(leaseId);
        },

        async getLease({ commit }, leaseId) {
            const lease = await leasesService.getLease(leaseId);
            commit('getLeaseSuccess', lease);
        },

        async editLease({ commit }, modifiedlease) {
            await leasesService.editLease(modifiedlease); 
        },


    },
    mutations: {
        getLeasesSuccess(state, leases) {
            state.leases = leases;
        },
        getLeaseSuccess(state, lease) {
            state.lease = lease;
        },
    },
    getters: {
        getLeasesData(state) {
            return state.leases?.data;
        },
        getLeasesMeta(state) {
            return state.leases?.meta;
        },
        getLease(state){
            return state.lease;
        }
    }
}

