<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 tag">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="tag d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Helyiség Lista</h5>
              </div>
              <div class="col-6 text-end">
                <material-button
                  class="float-right btn btm-sm"
                  @click="this.$router.push({ name: 'Add Area' })"
                >
                  <i class="fas fa-tag me-2"></i>
                  Helyiség Hozzáadása
                </material-button>
              </div>
            </div>
          </div>

          <!-- Card body -->
          <div class="px-0 pb-0 card-body">
            
            <div class="table-responsive p-4">
            <!-- Table -->
            <DataTable id="dataTable" :data="data" :columns="columns" ref="table" class="display table table-flush dataTable-table">
            </DataTable>
          </div>
        
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import eventTable from "@/mixins/eventTable.js";
import store from "@/store";
import _ from "lodash";

DataTable.use(DataTablesCore);

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import BasePagination from "@/components/BasePagination.vue";

var sortDirection = "created_at";

const getAreasList = _.debounce(async function (params) {
  await store.dispatch("areas/getAreas");
}, 300);

export default {
  name: "AreasList",
  components: {
    MaterialButton,
    MaterialInput,
    BasePagination,
    DataTable
  },
  mixins: [eventTable],
  setup() {
    const data = ref([]);
    const table = ref();
      const columns = [
          { title: 'Szám' },
          { title: 'Ingatlan' },
          { title: 'Hrsz' },
          { title: 'Felvitel Dátuma' },
          { title: 'Akció' },
        ];
        return {
            columns,
            data,
            table,
        }
    },
  data() {
    return {
      search: "",
      dataAreas: [],
      pagination: {},
      dataTable: null,
      keys: null,
    };
  },
  computed: {
    areasList() {
      return this.$store.getters["areas/getAreasData"];
    },
    metaPage() {
      return this.$store.getters["areas/getAreasMeta"];
    },
  },
  watch: {
    areasList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
  },
  async mounted() {
    await getAreasList({
        sort: sortDirection,
        query: "",
        //nr: getCurrentPage,
        //perpage: this.dataTable.options.perPage,
      });
  },
  beforeUnmount() {
    sortDirection = "created_at";
  },
  methods: {
    async getDataFromPage(page) {
      await getAreasList({
        sort: sortDirection,
        query: this.search,
        nr: page,
        perpage: this.dataTable.options.perPage,
      });
    },

    async reactiveTable() {
      this.dataAreas = [];
      if (this.areasList.length > 0) {
        this.areasList.forEach((area) => {
          this.dataAreas.push([
            area.area_nr,
            area.realestate_id,
            area.lot_number, 
            area.created_at,
              `<div class="text-end">${
                this.actionEditButton(area.id, "Helyiség Módosítása") +
                this.actionDeleteButton(area.id, "Helyiség törlése")
              }</div>`,
            ]);
        });
        this.data = this.dataAreas;
        this.removeEvent();
        await this.$nextTick();
        this.eventToCall({
          myUserId: null,
          redirectPath: "Edit Area",
          deletePath: "areas/deleteArea",
          getPath: "areas/getAreas",
          textDelete: "Helység sikeresen törölve!",
          textDefaultData: "areas",
        });
      } else {
        this.dataTable.setMessage("Nincs a keresési feltételnek megfelelő találat.");
      }
    },
  },
};
</script>


<style scoped lang="css">
  @import 'datatables.net-bs5';
</style>
