<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 tag">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="tag d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Tulajdonos Lista</h5>
              </div>
              <div class="col-6 text-end">
                <material-button
                  class="float-right btn btm-sm"
                  @click="this.$router.push({ name: 'Add Owner' })"
                >
                  <i class="fas fa-tag me-2"></i>
                  Tulajdonos Hozzáadása
                </material-button>
              </div>
            </div>
          </div>

          <!-- Card body -->
          <div class="px-0 pb-0 card-body">
            
            <div class="table-responsive p-4">
            <!-- Table -->
            <DataTable id="dataTable" :data="data" :columns="columns" ref="table" class="table table-flush dataTable-table" width="100%">
              <template #action="props">
                <td
                    class = "dt-control"
                    :text="`Col 1: ${props.cellData}`"
                    @click="rowClick"
                ></td>
              </template>
            </DataTable>
          </div>
        
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import eventTable from "@/mixins/eventTable.js";
import store from "@/store";
import _ from "lodash";

DataTable.use(DataTablesCore);

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import BasePagination from "@/components/BasePagination.vue";

var sortDirection = "created_at";


const getOwnersList = _.debounce(async function (params) {
  await store.dispatch("owners/getOwners");
}, 300);

export default {
  name: "OwnersList",
  components: {
    MaterialButton,
    MaterialInput,
    BasePagination,
    DataTable
  },
  mixins: [eventTable],
  setup() {
    const data = ref([]);
    const table = ref();
      const columns = [
          { title: 'Cégnév/Név' },
          { title: 'Adószám' },
          { title: 'Telefonszám' },
          { title: 'Email' },
          { title: 'Akció' },
        ];
        return {
            columns,
            data,
            table,
        }
    },
  data() {
    return {
      search: "",
      dataOwners: [],
      pagination: {},
      dataTable: null,
      keys: null,
    };
  },
  computed: {
    ownersList() {
      return this.$store.getters["owners/getOwnersData"];
    },
    metaPage() {
      return this.$store.getters["owners/getOwnersMeta"];
    },
  },
  watch: {
    ownersList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
  },
  async mounted() {
    await getOwnersList({
        sort: sortDirection,
        query: "",
        //nr: getCurrentPage,
        //perpage: this.dataTable.options.perPage,
      });
  },
  beforeUnmount() {
    sortDirection = "created_at";
  },
  methods: {
    async getDataFromPage(page) {
      await getOwnersList({
        sort: sortDirection,
        query: this.search,
        nr: page,
        perpage: this.dataTable.options.perPage,
      });
    },

    async reactiveTable() {
      this.dataOwners = [];
      if (this.ownersList.length > 0) {
        this.ownersList.forEach((owner) => {
          this.dataOwners.push([
          owner.name,
          owner.vat_number,
          owner.phone_number,
          owner.email,
            `<div class="text-end">${
              this.actionEditButton(owner.id, "Tulajdonos Módosítása") +
              this.actionDeleteButton(owner.id, "Tulajdonos Törlése")
            }</div>`,
          ]);
        });
        
        this.data = this.dataOwners;
        this.removeEvent();
        await this.$nextTick();
        this.eventToCall({
          myUserId: null,
          redirectPath: "Edit Owner",
          deletePath: "owners/deleteOwner",
          getPath: "owners/getOwners",
          textDelete: "Cég sikeresen törölve!",
          textDefaultData: "owners",
        });
      } else {
        this.dataTable.setMessage("Nincs a keresési feltételnek megfelelő találat.");
      }
    },
  },
};
</script>

<style scoped lang="css">
  @import 'datatables.net-bs5';
</style>