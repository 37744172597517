/* eslint-disable no-unused-vars */
import functionsService from '../services/afunctions.service';

const initialState = { afunctions: null, afunction: null };

export const afunctions = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getFunctions({ commit }, options) {
            const afunctions = await functionsService.getFunctions(options);
            commit('getFunctionsSuccess', afunctions);
        },

        async addFunction({ commit }, newfunction) {
            await functionsService.addFunction(newfunction);
        }, 

        async deleteFunction({ commit }, functionId) {
            await functionsService.deleteFunction(functionId);
        },

        async getFunction({ commit }, functionId) {
            const afunction = await functionsService.getFunction(functionId);
            commit('getFunctionSuccess', afunction);
        },

        async editFunction({ commit }, modifiedfunction) {
            await functionsService.editFunction(modifiedfunction); 
        },


    },
    mutations: {
        getFunctionsSuccess(state, afunctions) {
            state.afunctions = afunctions;
        },
        getFunctionSuccess(state, afunction) {
            state.afunction = afunction;
        },
    },
    getters: {
        getFunctionsData(state) {
            return state.afunctions?.data;
        },
        getFunctionsMeta(state) {
            return state.afunctions?.meta;
        },
        getFunction(state){
            return state.afunction;
        }
    }
}

