import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getPositions(params) {
        const response = await axios.get(API_URL + "/positions", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addPosition(position) {
        position.type = "positions"
        const newJson = dataFormatter.serialize({ stuff: position })
        const response = await axios.post(API_URL + "/positions", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deletePosition(positionId) {
        await axios.delete(API_URL + "/positions/" + positionId, { headers: authHeader() });
    },

    async getPosition(positionId) {
        const response = await axios.get(API_URL + "/positions/" + positionId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editPosition(position) {
        position.type = "positions"
        const newJson = dataFormatter.serialize({ stuff: position })
        const response = await axios.patch(API_URL + "/positions/" + position.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




