<template><!-- -------- START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- -->
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mx-auto mt-1 text-center col-8">
       
          © {{ new Date().getFullYear() }}, készült a
            <a href="https://syneo.hu" class="font-weight-bold" target="_blank">Syneo Kft.</a>
            által.

        </div>
      </div>
    </div>
</footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
