import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getHandlings(params) {
        const response = await axios.get(API_URL + "/handlings", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addHandling(handling) {
        handling.type = "handlings"
        const newJson = dataFormatter.serialize({ stuff: handling })
        const response = await axios.post(API_URL + "/handlings", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteHandling(handlingId) {
        await axios.delete(API_URL + "/handlings/" + handlingId, { headers: authHeader() });
    },

    async getHandling(handlingId) {
        const response = await axios.get(API_URL + "/handlings/" + handlingId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editHandling(handling) {
        handling.type = "handlings"
        const newJson = dataFormatter.serialize({ stuff: handling })
        const response = await axios.patch(API_URL + "/handlings/" + handling.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




