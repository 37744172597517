import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getUtilities(params) {
        const response = await axios.get(API_URL + "/utilities", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addUtility(utility) {
        utility.type = "utilities"
        const newJson = dataFormatter.serialize({ stuff: utility })
        const response = await axios.post(API_URL + "/utilities", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteUtility(utilityId) {
        await axios.delete(API_URL + "/utilities/" + utilityId, { headers: authHeader() });
    },

    async getUtility(utilityId) {
        const response = await axios.get(API_URL + "/utilities/" + utilityId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editUtility(utility) {
        utility.type = "utilities"
        const newJson = dataFormatter.serialize({ stuff: utility })
        const response = await axios.patch(API_URL + "/utilities/" + utility.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




