import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getMunits(params) {
        const response = await axios.get(API_URL + "/measurementunits", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addMunit(munit) {
        munit.type = "munits"
        const newJson = dataFormatter.serialize({ stuff: munit })
        const response = await axios.post(API_URL + "/measurementunits", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteMunit(munitId) {
        await axios.delete(API_URL + "/measurementunits/" + munitId, { headers: authHeader() });
    },

    async getMunit(munitId) {
        const response = await axios.get(API_URL + "/measurementunits/" + munitId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editMunit(munit) {
        munit.type = "munits"
        const newJson = dataFormatter.serialize({ stuff: munit })
        const response = await axios.patch(API_URL + "/measurementunits/" + munit.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




