/* eslint-disable no-unused-vars */
import ownersService from '../services/owners.service';

const initialState = { owners: null, owner: null };

export const owners = {
    namespaced: true,
    state: initialState,
    actions: {
        async getOwners({ commit }, options) {
            const owners = await ownersService.getOwners(options);
            commit('getOwnersSuccess', owners);
        },

        async addOwner({ commit }, newOwner) {
            await ownersService.addOwner(newOwner);
        },

        async deleteOwner({ commit }, ownerId) {
            await ownersService.deleteOwner(ownerId);
        },

        async getOwner({ commit }, ownerId) {
            const areas = await ownersService.getOwner(ownerId);
            commit('getOwnerSuccess', areas);
        },

        async editOwner({ commit }, modifiedowner) {
            await ownersService.editOwner(modifiedowner); 
        },


    },
    mutations: {
        getOwnersSuccess(state, owners) {
            state.owners = owners;
        },
        getOwnerSuccess(state, owners) {
            state.owners = owners;
        },
    },
    getters: {
        getOwnersData(state) {
            return state.owners?.data;
        },
        getOwnersMeta(state) {
            return state.owners?.meta;
        },
        getOwner(state){
            return state.owners;
        }
    }
}

