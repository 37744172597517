/* eslint-disable no-unused-vars */
import submetersService from '../services/submeters.service';

const initialState = { submeters: null, submeter: null };

export const submeters = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getSubMeters({ commit }, options) {
            const submeters = await submetersService.getSubMeters(options);
            commit('getSubMetersSuccess', submeters);
        },

        async addSubMeter({ commit }, newSubMeter) {
            await submetersService.addSubMeter(newSubMeter);
        }, 

        async deleteSubMeter({ commit }, submeterId) {
            await submetersService.deleteSubMeter(submeterId);
        },

        async getSubMeter({ commit }, submeterId) {
            const submeter = await submetersService.getSubMeter(submeterId);
            commit('getSubMeterSuccess', submeter);
        },

        async editSubMeter({ commit }, modifiedSubMeter) {
            await submetersService.editSubMeter(modifiedSubMeter); 
        },


    },
    mutations: {
        getSubMetersSuccess(state, submeters) {
            state.submeters = submeters;
        },
        getSubMeterSuccess(state, submeter) {
            state.submeter = submeter;
        },
    },
    getters: {
        getSubMetersData(state) {
            return state.submeters?.data;
        },
        getSubMetersMeta(state) {
            return state.submeters?.meta;
        },
        getSubMeter(state){
            return state.submeter;
        }
    }
}

