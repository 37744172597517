<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Kapcsolat Módosítása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Contacts List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-4">
                                    <material-input id="name" v-model:value="contact.name" label="Cégnév/Név"
                                        name="name" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.name"></validation-error>
                                </div>

                                <div class="col-4">
                                <material-input id="vat_number" v-model:value="contact.vat_number" label="Adószám"
                                    name="vat_number" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.vat_number"></validation-error>
                                </div>

                                <div class="col-4">
                                    <label>Kapcsolati besorolás</label>
                                    <select id="choices-contact_types_id" v-model.number="contact.contact_types_id" name="choices-contact_types_id" class="form-control">
                                        <option value="">Válassz típust!</option>
                                        <option v-for="type in getcontacttypesList" :key="type.id" :value="type.id">{{ type.name }}</option>
                                    </select>
                                </div>

                                <div class="col-6">
                                <material-input id="manager" v-model:value="contact.account_number" label="Ügyvezető"
                                    name="manager" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.manager"></validation-error>
                                </div>

                                <div class="col-3">
                                <material-input id="contact" v-model:value="contact.contact" label="Kapcsolattartó"
                                    name="contact" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.contact"></validation-error>
                                </div>

                                <div class="col-3">
                                <material-input id="phone_number" v-model:value="contact.phone_number" label="Telefonszám"
                                    name="phone_number" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.phone_number"></validation-error>
                                </div>

                                <div class="col-5">
                                <material-input id="address" v-model:value="contact.address" label="Levelezési cím"
                                    name="address" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.address"></validation-error>
                                </div>

                                <div class="col-5">
                                <material-input id="email" v-model:value="contact.email" label="Kapcsolattartási Email"
                                    name="email" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.email"></validation-error>
                                </div>

                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">
                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleEdit">Módosít</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import _ from "lodash"

export default {
    name: 'editContacts',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
    },
    mixins: [formMixin],
    data() {
        return {
            contact: {}
        }
    },
    computed: {
        getcontacttypesList() {
            return this.$store.getters["contacttypes/getContactTypesData"];
        },
    },
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    async mounted() {
        await this.$store.dispatch("contacttypes/getContactTypes");

        try {
            await this.$store.dispatch("contacts/getContact", this.$route.params.id);
            this.contact = _.omit(this.$store.getters['contacts/getContact'], 'links');
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: `Valami hiba történt!`,
                width: 500
            });
        }

        if (document.getElementById("choices-contact_types_id")) {
            const contact_types = document.getElementById("choices-contact_types_id");
            this.contact_types = new Choices(contact_types);
            this.contact_types.setChoiceByValue(String(this.contact.contact_types_id));
        }
    },
    methods: {
        async handleEdit() {
            this.resetApiValidation();
            try {
                await this.$store.dispatch("contacts/editContact", this.contact);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Kapcsolat sikeresen módosítva!",
                    width: 500
                });
                this.$router.push({ name: "Contacts List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        }
    }
}
</script>