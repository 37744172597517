/* eslint-disable no-unused-vars */
import positionsService from '../services/positions.service';

const initialState = { positions: null, position: null };

export const positions = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getPositions({ commit }, options) {
            const positions = await positionsService.getPositions(options);
            commit('getPositionsSuccess', positions);
        },

        async addPosition({ commit }, newposition) {
            await positionsService.addPosition(newposition);
        }, 

        async deletePosition({ commit }, positionId) {
            await positionsService.deletePosition(positionId);
        },

        async getPosition({ commit }, positionId) {
            const position = await positionsService.getPosition(positionId);
            commit('getPositionSuccess', position);
        },

        async editPosition({ commit }, modifiedposition) {
            await positionsService.editPosition(modifiedposition); 
        },


    },
    mutations: {
        getPositionsSuccess(state, positions) {
            state.positions = positions;
        },
        getPositionSuccess(state, position) {
            state.position = position;
        },
    },
    getters: {
        getPositionsData(state) {
            return state.positions?.data;
        },
        getPositionsMeta(state) {
            return state.positions?.meta;
        },
        getPosition(state){
            return state.position;
        }
    }
}

