<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-12">
                                <h5 class="mb-0">Fogyasztásregisztráció</h5>
                            </div>
                            
                            <div class="col-3 mb-4">
                                <label class="form-label mb-0 ms-0">Év/Hónap</label>
                                <VueDatePicker v-model="month" :teleport="true" locale="hu" month-picker />
                            </div>

                            <div class="col-3">
                                    <label class="form-label mb-0 ms-0"> Ingatlan megnevezése</label>
                                    <select id="choices-realestate" v-model.number="consumption.realestate_id" name="choices-realestate" class="form-control" @change="setRealEstate($event.target.value)">
                                        <option value="">Válassz!</option>
                                        <option v-for="realestate in getRealEstateList" :key="realestate.id" :value="realestate.id">{{ realestate.name }}</option>
                                    </select>
                                    <validation-error :errors="apiValidationErrors.pod"></validation-error>
                            </div>

                            <div class="col-4 mb-4">
                                <material-input id="realestate_address" v-model:value="consumption.realestate_address" label="Hely címe"
                                    name="realestate_address" variant="static" disabled></material-input>
                             </div>

                             <div class="col-5 mb-4">
                                    <label class="form-label mb-0 ms-0">Közmű szolgáltatás</label>
                                    <select id="choices-utility_services_id" v-model.number="consumption.utility_services_id" name="choices-utility_services_id" class="form-control">
                                        <option v-for="sutility in getutilityList" :key="sutility.id" :value="sutility.id">{{ sutility.name }}</option>
                                    </select>
                                </div>

                            <div class="col-2 text-end">
                                <material-button class="float-right btn btm-sm">
                                    Kezdés
                                </material-button>
                            </div>
                        </div>

                        
                    </div>
                    <!--Card body-->
                    <div class="px-0 pb-0 card-body">
                    <!-- Search -->
                    <div class="dataTable-search search-block col-2 ms-3">
                    <material-input
                        id="id"
                        v-model:value="search"
                        placeholder="Keresés..."
                        class="mb-1 dataTable-input search-input-table"
                        variant="static"
                    />
                    </div>

                    <!-- Table -->
                    <div class="table-responsive">
                    <table id="tag-list" ref="table" class="table table-flush">
                        <thead class="thead-light">
                        </thead>
                        <tbody class="text-sm"></tbody>
                    </table>
                    </div>
                </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import { ref } from 'vue';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'Consumption',
    components: {
        MaterialButton,
        MaterialInput,
        VueDatePicker,
    },
    mixins: [formMixin],
    setup() {
        const month = ref({
            month: new Date().getMonth(),
            year: new Date().getFullYear()
        });
        return {
            color: '#33e8f5',
            month,
        }
    },
    data() {
        return {
            consumption: {},
            dataConsumption: [],
            date: null,
            dataTable: null,
        }
    },
    computed: {
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        getutilityList() {
            return this.$store.getters["utilities/getUtilitiesData"];
        },
    },
    async mounted() {
        await this.$store.dispatch("realestates/getRealEstates");
        await this.$store.dispatch("utilities/getUtilities");

        if (document.getElementById("choices-realestate")) {
            const realestate = document.getElementById("choices-realestate");
            this.realestate = new Choices(realestate);
            if (this.realestate.getValue(true) != undefined){
                this.currentRealEstate = this.getRealEstateList.filter(value => {
                    return value.id == this.realestate.getValue(true)
                });
                await this.$store.dispatch("areas/getAreas", {
                    filter: {
                        ...(this.realestate.getValue(true) ? { realestate_id: String(this.realestate.getValue(true)) } : {}),
                    },
                });
                document.getElementById('realestate_address').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.zip + ' ' + this.currentRealEstate[0]?.city + ' ' +  this.currentRealEstate[0]?.address;
            }
        }

        if (document.getElementById("choices-utility_services_id")) {
            var mutility = document.getElementById("choices-utility_services_id");
            new Choices(mutility);
        }
    },
    
    methods: {
        async setRealEstate(id) {
            this.currentRealEstate = this.getRealEstateList.filter(value => {
                return value.id == id
            });
            await this.$store.dispatch("areas/getAreas", {
                    filter: {
                        ...(id ? { realestate_id: id } : {}),
                    },
                });
            document.getElementById('realestate_address').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.zip + ' ' + this.currentRealEstate[0]?.city + ' ' +  this.currentRealEstate[0]?.address;
        },
    }
}
</script>