/* eslint-disable no-unused-vars */
import servicesService from '../services/services.service';

const initialState = { services: null, service: null };

export const services = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getServices({ commit }, options) {
            const services = await servicesService.getServices(options);
            commit('getServicesSuccess', services);
        },

        async addService({ commit }, newService) {
            await servicesService.addService(newService);
        }, 

        async deleteService({ commit }, serviceId) {
            await servicesService.deleteService(serviceId);
        },

        async getService({ commit }, serviceId) {
            const service = await servicesService.getService(serviceId);
            commit('getServiceSuccess', service);
        },

        async editService({ commit }, modifiedService) {
            await servicesService.editService(modifiedService); 
        },


    },
    mutations: {
        getServicesSuccess(state, services) {
            state.services = services;
        },
        getServiceSuccess(state, service) {
            state.service = service;
        },
    },
    getters: {
        getServicesData(state) {
            return state.services?.data;
        },
        getServicesMeta(state) {
            return state.services?.meta;
        },
        getService(state){
            return state.service;
        }
    }
}

