/* eslint-disable no-unused-vars */
import munitsService from '../services/munits.service';

const initialState = { munits: null, munit: null };

export const munits = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getMunits({ commit }, options) {
            const munits = await munitsService.getMunits(options);
            commit('getMunitsSuccess', munits);
        },

        async addMunit({ commit }, newmunit) {
            await munitsService.addMunit(newmunit);
        }, 

        async deleteMunit({ commit }, munitId) {
            await munitsService.deleteMunit(munitId);
        },

        async getMunit({ commit }, munitId) {
            const munit = await munitsService.getMunit(munitId);
            commit('getmunitSuccess', munit);
        },

        async editMunit({ commit }, modifiedmunit) {
            await munitsService.editMunit(modifiedmunit); 
        },


    },
    mutations: {
        getMunitsSuccess(state, munits) {
            state.munits = munits;
        },
        getMunitSuccess(state, munit) {
            state.munit = munit;
        },
    },
    getters: {
        getMunitsData(state) {
            return state.munits?.data;
        },
        getMunitsMeta(state) {
            return state.munits?.meta;
        },
        getMunit(state){
            return state.munit;
        }
    }
}

