<template>
  <div id="sidenav-collapse-main" class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100 scrollable-list">
    <ul class="navbar-nav">
      <li class="nav-item" :class="getRoute() === 'account' ? 'active' : ''">
        <sidenav-collapse :collapse-ref="getName" :nav-text="getName"
          :class="getRoute() === 'account' ? 'active' : ''">
          <template #icon>
            <img :src="getImage" class="avatar" />
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'User Profile' }" mini-icon="P" text="Profil" />
              <sidenav-item :to="{ name: 'Settings' }" mini-icon="S" text="Beállítások" />
              <sidenav-item :to="{ name: 'Login' }" mini-icon="L" text="Kilépés" @click="logout" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="dashboardsExamples" nav-text="Dashboard"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">dashboard</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Default' }" mini-icon="D" text="Analitika" />
              <sidenav-item :to="{ name: 'Sales' }" mini-icon="S" text="Értékesítés" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="mt-3 nav-item">
        <h6 class="text-xs ps-4 text-uppercase font-weight-bolder text-white" :class="isRTL ? 'me-4' : 'ms-2'">
          OLDALAK
        </h6>
      </li>


      <li class="nav-item" :class="getRoute() === 'account' ? 'active' : ''">
        <sidenav-collapse collapse-ref="account" nav-text="Nyilvántartás" :class="getRoute() === 'account' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">image</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Owners List' }" mini-icon="C" text="Cégcsoport" />
              <sidenav-item :to="{ name: 'RealEstates List' }" mini-icon="I" text="Ingatlanok" />
              <sidenav-item :to="{ name: 'Utilities List' }" mini-icon="KH" text="Közműhálózat" />
              <sidenav-item :to="{ name: 'Meters List' }" mini-icon="MR" text="Mérő reg." />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" :class="getRoute() === 'contacts' ? 'active' : ''">
        <sidenav-collapse collapse-ref="contacts" nav-text="Partnerkezelés" :class="getRoute() === 'contacts' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">content_paste</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Contacts List' }" mini-icon="KL" text="Kontaktlista" />
              <sidenav-item :to="{ name: 'Leases List' }" mini-icon="BSZ" text="Bérleti szerződések" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" :class="getRoute() === 'invoice' ? 'active' : ''">
        <sidenav-collapse collapse-ref="applicationsExamples" nav-text="Fogyasztáskezelés"
          :class="getRoute() === 'applications' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">apps</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Consumption' }" mini-icon="FR" text="Fogyasztásregisztráció" />
              <sidenav-item :to="{ name: '' }" mini-icon="FK" text="Fogyasztáskalkulátor" />
              <sidenav-item :to="{ name: 'Calculation' }" mini-icon="EL" text="Elszámoló Lap" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" :class="getRoute() === 'invoice' ? 'active' : ''">
        <sidenav-collapse collapse-ref="applicationsExamples" nav-text="Költségkezelés"
          :class="getRoute() === 'applications' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">apps</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'InInvoice List' }" mini-icon="SZK" text="Számla felvitel" />
              <sidenav-item :to="{ name: '' }" mini-icon="SZF" text="Számla kiállítás" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="basicExamples" nav-text="Beállítások" icon="dashboard"
          :class="getRoute() === 'basic' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">settings</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-collapse-item refer="gettingStartedExample" mini-icon="G" text="Felhasználók">
                <template #nav-child-item>
                  <sidenav-item :to="{ name: 'Users List' }" mini-icon="I" text="Felhasználó Lista" />
                  <sidenav-item :to="{ name: 'Roles List' }" mini-icon="I" text="Szerepkörök" />
                </template>
              </sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="mt-3 nav-item">
        <hr class="horizontal light mt-0" />
        <h6 class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder text-white" :class="isRTL ? 'me-4' : 'ms-2'">
          DOKUK
        </h6>
      </li>
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="basicExamples" nav-text="Manual" icon="dashboard"
          :class="getRoute() === 'basic' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">upcoming</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-collapse-item refer="gettingStartedExample" mini-icon="G" text="Rendszer Leírások">
                <template #nav-child-item>
                  <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
                    <a class="nav-link"
                      href="#"
                      target="_blank">
                      <span class="text-xs sidenav-mini-icon">Q</span>
                      <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Leírás</span>
                    </a>
                  </li>
                  <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
                    <a class="nav-link" href="#"
                      target="_blank">
                      <span class="text-xs sidenav-mini-icon">L</span>
                      <span class="sidenav-normal me-3 ms-3 ps-1 text-white">Licensz</span>
                    </a>
                  </li>
                </template>
              </sidenav-collapse-item>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse nav-text="VáltozásLog" :collapse="false" url="#" :aria-controls="''"
          collapse-ref="#">
          <template #icon>
            <i class="material-icons-round opacity-10">receipt_long</i>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import { mapState } from "vuex";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCollapseItem,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      profile_image: null,
      profile_name: null,
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    getImage() {
      if (!this.profile_image) return require("@/assets/img/placeholder.jpg");
      else {
        return this.profile_image;
      }
    },
    getName() {
      if (!this.profile_name) return 'Unknown';
      else {
        return this.profile_name;
      }
    },
    role() {
      const user = this.$store.getters["profile/getUserProfile"];
      return user?.roles[0]?.name;
    },
  },
  async created() {
    try {
      await this.$store.dispatch("profile/getProfile");
      this.profile_image = this.$store.getters["profile/getUserProfileImage"];
      this.profile_name = this.$store.getters["profile/getUserProfile"].name;
      this.initialImageUrl = this.getImage;
      //console.log(this.$store.getters["profile/getUserProfile"]);
    } catch (error) {
      try {
        this.$store.dispatch("auth/logout");
      } finally {
        this.$router.push({ name: "Login" });
      }
    }
  },

  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<style>
.scrollable-list {
  max-height: fit-content;
  overflow-y: auto;
  scrollbar-width: none;
}

.scrollable-list::-webkit-scrollbar {
  width: 0;
}
</style>