import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getMeters(params) {
        const response = await axios.get(API_URL + "/getmeters", { headers: authHeader(), params: params });
        return { data: response.data, meta: response.data.meta?.page };
    },

    async addMeter(meter) {
        meter.type = "meters"
        const newJson = dataFormatter.serialize({ stuff: meter })
        const response = await axios.post(API_URL + "/addmeter", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteMeter(meterId) {
        await axios.delete(API_URL + "/meters/" + meterId, { headers: authHeader() });
    },

    async getMeter(meterId) {
        const response = await axios.get(API_URL + "/meters/" + meterId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editMeter(meter) {
        meter.type = "meters"
        const newJson = dataFormatter.serialize({ stuff: meter })
        const response = await axios.patch(API_URL + "/meters/" + meter.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




