<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Elszámoló Lap</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <label class="form-label mb-0 ms-0">Év/Hónap</label>
                                <VueDatePicker v-model="month" :teleport="true" locale="hu" month-picker />
                            </div>

                            <div class="col-4">
                                    <label class="form-label mb-0 ms-0">Fogyasztási hely</label>
                                    <select id="realestate_id" v-model="calculation.realestate_id" name="realestate_id" class="form-control">
                                        <option v-for="realestate in getRealEstateList" :key="realestate.id" :value="realestate.id">{{ realestate.name }}</option>
                                    </select>
                                </div>

                            <div class="col-2 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click.prevent="showCalculation">
                                    Mutat
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="px-0 pb-0 card-body">
                    <!-- Search -->
                    <div class="dataTable-search search-block col-2 ms-3">
                    <material-input
                        id="id"
                        v-model:value="search"
                        placeholder="Keresés..."
                        class="mb-1 dataTable-input search-input-table"
                        variant="static"
                    />
                    </div>

                    <!-- Table -->
                    <div class="table-responsive">
                    <table id="tag-list" ref="table" class="table table-flush">
                        <thead class="thead-light">
                        <tr>
                            <th data-sortable="false" class="w-20">Helység</th>
                            <th title="Size" class="w-20">Méret</th>
                            <th title="created_at" class="w-20">Összeg</th>
                            <th title="created_at" class="w-20">ÉV</th>
                            <th data-sortable="false" class="text-end">Hónap</th>
                        </tr>
                        </thead>
                        <tbody class="text-sm"></tbody>
                    </table>
                    </div>
                </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { DataTable } from "simple-datatables";
import MaterialButton from "@/components/MaterialButton.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'Calculation',
    components: {
        MaterialButton,
        VueDatePicker,
    },
    mixins: [formMixin],
    setup() {
        //const calculation = {};
        const month = ref({
            month: new Date().getMonth(),
            year: new Date().getFullYear()
        });
        return {
            color: '#33e8f5',
            month,
        }
    },
    data() {
        return {
            calculation: {},
            dataCalculation: [],
            date: null,
            dataTable: null,
        }
    },
    computed: {
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        },
        getCalculationList() {
            const test = this.$store.getters["calculation/getCalculationData"];
            return test;
        },
    },
    watch: {
        getCalculationList: {
            handler: "reactiveTable",
            immediate: false,
            deep: true,
        },
    },
    async mounted() {
        await this.$store.dispatch("realestates/getRealEstates");

        if (this.$refs.table) {
            this.dataTable = new DataTable(this.$refs.table, {
                searchable: false,
                fixedHeight: false,
                perPage: 5,
            });
        }
    },
    
    methods: {
        async showCalculation() {
            this.resetApiValidation();
            try {
                this.calculation.month = this.month;
                await this.$store.dispatch("calculation/getCalculation", this.calculation);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Számítás befejezve!",
                    width: 500
                }); 
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        async reactiveTable() {
            this.dataCalculation = [];
            if (this.getCalculationList.data.length > 0) {
                this.getCalculationList.data.forEach((calculation) => {
                    console.log(calculation);
                this.dataCalculation.push([
                    calculation.area_name,
                    calculation.area_size.toString(),
                    calculation.amount.toString(),
                    calculation.inv_year,
                    calculation.inv_month,
                ]);
                });
                this.dataTable.data = [];
                this.dataTable.refresh();
                this.dataTable.insert({ data: this.dataCalculation });
                //this.removeEvent();
                //this.eventToCall({
                //table: this.dataTable,
                //myUserId: null,
                //});
            } else {
                this.dataTable.setMessage("Nincs a keresési feltételnek megfelelő találat.");
            }
        },
    }
}
</script>