import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getFunctions(params) {
        const response = await axios.get(API_URL + "/afunctions", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addFunction(afunction) {
        afunction.type = "afunctions"
        const newJson = dataFormatter.serialize({ stuff: afunction })
        const response = await axios.post(API_URL + "/afunctions", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteFunction(functionId) {
        await axios.delete(API_URL + "/afunctions/" + functionId, { headers: authHeader() });
    },

    async getFunction(functionId) {
        const response = await axios.get(API_URL + "/afunctions/" + functionId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editFunction(afunction) {
        afunction.type = "afunctions"
        const newJson = dataFormatter.serialize({ stuff: afunction })
        const response = await axios.patch(API_URL + "/afunctions/" + afunction.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




