import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getNoticeperiods(params) {
        const response = await axios.get(API_URL + "/noticeperiods", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addNoticeperiod(noticeperiod) {
        noticeperiod.type = "noticeperiods"
        const newJson = dataFormatter.serialize({ stuff: noticeperiod })
        const response = await axios.post(API_URL + "/noticeperiods", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteNoticeperiod(noticeperiodId) {
        await axios.delete(API_URL + "/noticeperiods/" + noticeperiodId, { headers: authHeader() });
    },

    async getNoticeperiod(noticeperiodId) {
        const response = await axios.get(API_URL + "/noticeperiods/" + noticeperiodId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editNoticeperiod(noticeperiod) {
        noticeperiod.type = "noticeperiods"
        const newJson = dataFormatter.serialize({ stuff: noticeperiod })
        const response = await axios.patch(API_URL + "/noticeperiods/" + noticeperiod.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




