/* eslint-disable no-unused-vars */
import noticeperiodsService from '../services/noticeperiods.service';

const initialState = { noticeperiods: null, noticeperiod: null };

export const noticeperiods = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getNoticeperiods({ commit }, options) {
            const noticeperiods = await noticeperiodsService.getNoticeperiods(options);
            commit('getNoticeperiodsSuccess', noticeperiods);
        },

        async addNoticeperiod({ commit }, newnoticeperiod) {
            await noticeperiodsService.addNoticeperiod(newnoticeperiod);
        }, 

        async deleteNoticeperiod({ commit }, noticeperiodId) {
            await noticeperiodsService.deleteNoticeperiod(noticeperiodId);
        },

        async getNoticeperiod({ commit }, noticeperiodId) {
            const noticeperiod = await noticeperiodsService.getNoticeperiod(noticeperiodId);
            commit('getNoticeperiodSuccess', noticeperiod);
        },

        async editNoticeperiod({ commit }, modifiednoticeperiod) {
            await noticeperiodsService.editNoticeperiod(modifiednoticeperiod); 
        },


    },
    mutations: {
        getNoticeperiodsSuccess(state, noticeperiods) {
            state.noticeperiods = noticeperiods;
        },
        getNoticeperiodSuccess(state, noticeperiod) {
            state.noticeperiod = noticeperiod;
        },
    },
    getters: {
        getNoticeperiodsData(state) {
            return state.noticeperiods?.data;
        },
        getNoticeperiodsMeta(state) {
            return state.noticeperiods?.meta;
        },
        getNoticeperiod(state){
            return state.noticeperiod;
        }
    }
}

