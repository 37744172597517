<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Közmű módosítása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Utilities List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Fogyasztási hely címe</label>
                                    <select id="choices-realestate" v-model.number="computedRealEstateId" name="choices-realestate" class="form-control"  @change="setCity($event.target.value)">
                                        <option v-for="realestate in getRealEstateList" :key="realestate.id" :value="realestate.id">{{ realestate.zip + ' ' + realestate.city + ' ' + realestate.address }}</option>
                                    </select>
                                    <validation-error :errors="apiValidationErrors.realestate_id"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="realestate_address" label="Fogyasztási hely neve"
                                        name="realestate_address" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.pod"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Közmű szolgáltatás</label>
                                    <select id="choices-utility_services" v-model.number="utility.utility_services_id" name="choices-utility_services" class="form-control">
                                        <option v-for="sutility in getutilityList" :key="sutility.id" :value="sutility.id">{{ sutility.name }}</option>
                                    </select>
                                </div>

                                <div class="col-5">
                                    <material-input id="network_provider_name" v-model:value="utility.network_provider_name"
                                        label="Hálózati szolgáltató" name="network_provider_name" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.network_provider_name"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="pod" v-model:value="utility.pod"
                                        label="POD" name="pod" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.pod"></validation-error>
                                </div>
                                
                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">

                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-left btn btm-sm" @click.prevent="handleEdit">Módosítás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Choices from 'choices.js';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import _ from "lodash"

export default {
    name: 'EditUtility',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
    },
    mixins: [formMixin],
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    data() {
        return {
            utility: {} 
        }
    },
    computed: {
        getutilityList() {
            return this.$store.getters["utilities/getUtilitiesData"];
        },
        getRealEstateList() {
            return this.$store.getters["realestates/getRealEstatesData"];
        }
    },
    async mounted() {
        await this.$store.dispatch("utilities/getUtilities");
        await this.$store.dispatch("realestates/getRealEstates");
        try {
            await this.$store.dispatch("unetworks/getUnetwork", this.$route.params.id);
            this.utility = _.omit(this.$store.getters['unetworks/getUnetwork'], 'links');
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: `Valami hiba történt!`,
                width: 500
            });
        }

        if (document.getElementById("choices-realestate")) {
            var realestate = document.getElementById("choices-realestate");
            this.realestate = new Choices(realestate);
            this.realestate.setChoiceByValue(String(this.utility.realestate_id));
            this.currentRealEstate = this.getRealEstateList.filter(value => {
                return value.id.includes(this.utility.realestate_id)
            });
            document.getElementById('realestate_address').value = this.currentRealEstate[0].name;
        }
        if (document.getElementById("choices-utility_services")) {
            var utility_services = document.getElementById("choices-utility_services");
            this.utility_services = new Choices(utility_services);
            this.utility_services.setChoiceByValue(String(this.utility.utility_services_id));
        }
    },
    methods: {
        async handleEdit() {
            this.resetApiValidation();
            try {
                
                await this.$store.dispatch("unetworks/editUnetwork", this.utility);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Főmérő sikeresen módosítva!",
                    width: 500
                });
                this.$router.push({ name: "utilitys List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        setCity(event) {
            this.currentRealEstate = this.getRealEstateList.filter(value => {
                return value.id.includes(event)
            });
            document.getElementById('realestate_address').value = this.currentRealEstate[0].name;
        },
    }
}
</script>