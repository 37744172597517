/* eslint-disable no-unused-vars */
import coveringsService from '../services/coverings.service';

const initialState = { coverings: null, covering: null };

export const coverings = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getCoverings({ commit }, options) {
            const coverings = await coveringsService.getCoverings(options);
            commit('getCoveringsSuccess', coverings);
        },

        async addCovering({ commit }, newcovering) {
            await coveringsService.addCovering(newcovering);
        }, 

        async deleteCovering({ commit }, coveringId) {
            await coveringsService.deleteCovering(coveringId);
        },

        async getCovering({ commit }, coveringId) {
            const covering = await coveringsService.getCovering(coveringId);
            commit('getcoveringSuccess', covering);
        },

        async editcovering({ commit }, modifiedcovering) {
            await coveringsService.editcovering(modifiedcovering); 
        },


    },
    mutations: {
        getCoveringsSuccess(state, coverings) {
            state.coverings = coverings;
        },
        getCoveringSuccess(state, covering) {
            state.covering = covering;
        },
    },
    getters: {
        getCoveringsData(state) {
            return state.coverings?.data;
        },
        getCoveringsMeta(state) {
            return state.coverings?.meta;
        },
        getCovering(state){
            return state.covering;
        }
    }
}

