import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getAreas(params) {
        const response = await axios.get(API_URL + "/areas", { headers: authHeader(), params: params });
        return { data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page };
    },

    async addArea(area) {
        area.type = "areas"
        const newJson = dataFormatter.serialize({ stuff: area })
        const response = await axios.post(API_URL + "/areas", newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async deleteArea(areaId) {
        await axios.delete(API_URL + "/areas/" + areaId, { headers: authHeader() });
    },

    async getArea(areaId) {
        const response = await axios.get(API_URL + "/areas/" + areaId, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },

    async editArea(area) {
        area.type = "areas"
        const newJson = dataFormatter.serialize({ stuff: area })
        const response = await axios.patch(API_URL + "/areas/" + area.id, newJson, { headers: authHeader() });
        return dataFormatter.deserialize(response.data);
    },
}




